<template>
    <div>
      <!-- <BarGraph /> -->
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12 sm6 md6 lg6 xl6>
          <v-layout wrap justify-start>
            <v-flex xs12>
              <v-img
                src="../assets/images/authentication/loginimage.png"
                fill-height
                height="695px"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
            <v-layout wrap justify-center pt-16>
              <v-flex xs12 pt-16>
                <v-layout pt-16>
                  <v-flex xs12 align-self-center>
                    <v-img
                      contain
                      width="100%"
                      height="110px"
                      src="../assets/homelogo.png"
                      fill-height
                    ></v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md9 align-self-center>
                <v-layout wrap justify-center>
                  <!-- <v-flex xs12 text-center>
                    <span
                      style="
                        font-family: sofiaProSemiBold;
                        font-size: 20px;
                        cursor: pointer;
                        color: black;
                      "
                    >
                      HWC
                    </span>
                  </v-flex> -->
  
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs6 pt-2 lg4 text-center>
                        <span
                          style="
                            font-family: sofiaProSemiBold;
                            font-size: 20px;
                            color: #000;
                          "
                        >
                          Log In
                        </span>
                        <v-progress-linear
                          height="4"
                          value="100"
                          color="#314755"
                        ></v-progress-linear>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex pt-6 xs12 text-left>
                    <v-layout wrap justify-center>
                      <v-flex xs10 lg8 sm8 pt-4>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field
                            class="mainfont"
                            color="#717171"
                            placeholder="Username"
                            outlined
                            dense
                            style="font-family: sofiaProRegular"
                            v-model="emailId"
                            hide-details
                          >
                          </v-text-field>
                        </v-form>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-center>
                      <v-flex xs10 lg8 sm8 pt-4>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            color="#717171"
                            placeholder="Password"
                            v-model="password"
                            outlined
                            dense
                            style="font-family: sofiaProRegular"
                            class="input-group--focused"
                            @click:append="show3 = !show3"
                          ></v-text-field>
                        </v-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs10 lg8 sm8 pb-2>
                    <v-btn
                      class="no-uppercase mainfont"
                      dark
                      block
                      color="#314755"
                      @click="validateInput"
                    >
                      LOGIN
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end>
              <v-flex xs12 pt-16>
                <v-layout wrap justify-end fill-height>
                  <v-flex xs12 align-self-end>
                    <v-footer
                      color="#000"
                      :min-height="
                        $vuetify.breakpoint.name == 'xs' ? 'auto' : '81px'
                      "
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex align-self-center xs12 px-md-4 px-lg-6>
                              <v-layout
                                wrap
                                justify-center
                                :text-left="
                                  $vuetify.breakpoint.name == 'xs' ? true : false
                                "
                              >
                                <v-flex xs12 sm2 md2 lg2 align-self-center>
                                </v-flex>
                                <v-flex xs12 sm10 md8 lg8 align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex xs12 align-self-center text-center>
                                      <span
                                        style="
                                          color: #FFFFFF;
                                          font-family: poppinsregular;
                                          font-size: 13px;
                                          font-weight: 500;
                                        "
                                        >Designed & Maintained By Leopard Tech Labs</span
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex
                                  xs5
                                  py-sm-4
                                  py-md-2
                                  sm3
                                  md2
                                  lg2
                                  py-2
                                  align-self-end
                                  text-right
                                >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-footer>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import store from "../store";
  // import BarGraph from './BarGraph';
  export default {
    components: {
      // BarGraph,
    },
    data() {
      return {
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        otp: null,
        phone: null,
        emailId: null,
        password: null,
        section:'hwc',
        show1: false,
        show2: true,
        show3: false,
        show4: false,
      };
    },
    mounted() {
      this.phone = this.$route.query.phone;
    },
    methods: {
      validateInput() {
        if (!this.emailId) {
          this.msg = "Please Provide Username.";
          this.showSnackBar = true;
          return;
        }
        if (!this.password) {
          this.msg = "Please Provide Password.";
          this.showSnackBar = true;
          return;
        } else {
          this.login();
        }
      },
      login() {
        axios({
          method: "POST",
          url: "/platform/login/",
          data: {
            username: this.emailId,
            password: this.password,
          },
        })
          .then((response) => {
            if (response.data.heartbeat) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              //OLD WILDWATCH REQ
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("userRole", response.data.data.type);
              localStorage.setItem("activeSection", this.section);
              this.$store.commit("userData", response.data.userDetails);
              this.$store.commit("loginUser", response.data.data.token);
              this.$store.commit("userRole", response.data.data.type);
              this.$store.commit("mainRole", response.data.data.role);
              this.$store.commit("userName", response.data.data.username);
              if (response.data.data.role == "official")
                this.$router.push("/officers");
              
              else if (response.data.data.type == "admin")
                // this.$router.push("/admin");
                this.$router.push("/admin/commonanalyticspage");
                else if (response.data.data.type == "SEOC")
                // this.$router.push("/admin");
                this.$router.push("/admin/commonanalyticspage");
              else if (response.data.data.type == "EmergencyUser")
                this.$router.push("/officers");
              else if (response.data.data.type == "RRT")
                this.$router.push("/officers");
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            store.commit("appLoading", false);
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
       
    